// .adv__submit {
// }

// .avd__search {
// }
.ant-drawer .ant-drawer-header {
    display: flex;
    flex: 0;
    align-items: center;
    padding: 19px 24px;
    font-size: 16px;
    line-height: 1.5;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}
.adv_submit_area{
    margin-top: 30px;
    display: flex;
}