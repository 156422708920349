.error_container {
  height: 100%;
  display: flex;
  align-items: center;
  .ant-space {
    width: 100%;
  }
  .title {
    &__code {
      font-size: 160px;
      margin: 0;
      font-weight: 850;
      letter-spacing: 16px;
      background: url(../../assets/images/errorbg.jpg) center no-repeat;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &__subtitle {
      font-size: 30px;
    }
  }
}
