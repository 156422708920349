@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
// font-family: 'Satisfy', cursive;
:root {
  --header-height: 3.5rem;
  --body-color: rgba(16, 68, 106, 0.9);
  --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
  :root {
    --biggest-font-size: 4rem;
    --h2-font-size: 1.5rem;
    --normal-font-size: 1rem;
    --smaller-font-size: 0.813rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  // background-color: var(--body-color);
  color: var(--text-color);
}

ul {
  list-style-type: square;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.main__loginpage {
  overflow: hidden;
  background: #10446ae6;
  .home__login {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .login-shape-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      mix-blend-mode: soft-light;
      opacity: 0.5;
    }
    .container {
      max-width: 1280px;
      margin-inline: 1.5rem;
    }

    .login__container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1;
    }

    .login__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: 50%;
      align-items: center;
      border-right: 3px solid #ddd;
      border-radius: 30px;
    }
    .login__leftside__info {
      padding-right: 100px;
    }

    .login__left__title {
      margin-bottom: 0.75rem;
      line-height: 50px;
      font-size: 44px;
      color: #5ac09b;
      font-weight: 700;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    }
    .login__left__des {
      color: #fff;
      margin-bottom: 1rem;
      font-size: 18px;
      font-weight: 300;
    }

    .login__left__point {
      padding-left: 20px;
      color: #fff;
      margin-top: 20px;
    }

    .login__left__point ul li {
      padding: 5px 0;
    }

    .owner__quote__area {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      gap: 10px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      padding: 30px 20px;
      margin-top: 40px;
      border-top: 8px solid #5ac09b;
    }
    .owner__quote {
      color: #fff;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: 400;
      text-transform: inherit;
    }

    .ownwr__img {
      width: 150px;
      height: 150px;
    }

    .ownwr__img img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      object-fit: cover;
    }
    .icon-quote-left {
      padding-right: 15px;
      color: #5ac09b;
    }

    .icon-quote-right {
      padding-left: 15px;
      color: #5ac09b;
    }

    .owner__name {
      color: #5ac09b;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.03rem;
      position: relative;
      text-align: center;
    }
    .owner__name::before {
      position: absolute;
      content: "";
      height: 1px;
      width: 40px;
      background: #fff;
      top: 50%;
      left: 25%;
    }

    .owner__address {
      color: #fff;
      font-size: 14px;
    }

    .avatar {
      border-radius: 6px;
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    .login__form__area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-basis: 50%;
    }

    .home__subtitle {
      margin-bottom: 1rem;
    }

    .home__image {
      display: grid;
    }

    .home__image__earth img {
      filter: drop-shadow(0 4px 32px hsla(203, 71%, 60%, 0.5));
    }

    .login___form h3 {
      font-size: 2.4rem;
      color: #135280;
      margin-bottom: 15px;
      font-family: "Satisfy", cursive;
    }

    .login___form p {
      font-size: 0.9rem;
      color: #135280;
      padding: 8px 0;
      margin-bottom: 15px;
      text-transform: capitalize;
    }

    .login___form {
      width: 450px;
      background: #fff;
      padding: 20px 50px;
      border-radius: 5px;
      // text-align: center;
    }

    .login-form-forgot {
      float: right;
      font-weight: 500;
      color: #135280;
    }

    .login-form-signin {
      text-align: center;
    }

    .login-form-signin a {
      color: #5ac09b;
      font-weight: 800;
    }

    .login-form-signin a:hover {
      color: #38bd8c;
      font-weight: 800;
    }

    .ant-form-item-label {
      padding: 0px 0 3px !important;
    }

    .login-form-button {
      width: 100%;
      background: #5ac09b;
      margin: 10px 0 20px 0;
      color: #fff;
    }

    .login-form-button:hover {
      width: 100%;
      background: #38bd8c !important;
      margin: 10px 0 20px 0;
      color: #fff;
    }

    .form__item__element {
      margin-bottom: 20px !important;
    }

    .login___form .ant-form-item-label > label {
      color: #135280 !important;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .login___form .ant-checkbox-wrapper {
      color: #135280;
    }

    .login___form .ant-input-affix-wrapper {
      color: #135280 !important;
      border-color: #135280 !important;
      box-shadow: none !important;
    }

    @media (min-width: 280px) and (max-width: 319px) {
      .login__info {
        display: none;
      }
      .login__container {
        justify-content: center;
      }
      .login___form {
        padding: 20px 20px;
        max-width: max-content;
      }
    }

    @media (min-width: 320px) and (max-width: 1305px) {
      .login__info {
        display: none;
      }
      .login__container {
        justify-content: center;
      }
      .login___form {
        padding: 20px 75px;
      }
    }
  }
}

.login__right {
  display: flex;
  flex-direction: column;
  align-items: center;
}
// .footer__area {
//   position: absolute;
//   text-align: center;
//   bottom: 10px;
//   left: 43%;
//   color: #fff;
//   opacity: 0.8;
// }
.signin_copyright{
  color: #fff;
  opacity: 0.8;
  text-align: center;
  margin-top: 100px;
  width: 100%;
  a{
    margin-left: 8px;
    color: #fff;
    font-weight: 700;
  }
}