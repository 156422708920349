.registrtion__area {
  overflow: hidden;
  .reg_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    img.reg_area_bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(19, 57, 154, 0.5);
    }
    .container {
      // max-width: 1280px;
      // margin-inline: 1.5rem;
      max-width: 1000px;
      margin: 0 auto;
      background: #fff;
    }
    .reg_container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1;
      overflow: hidden;
    }
    .reg_left_area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: 30%;
      align-items: center;
      background: #38bd8c;
      min-height: 645px;
      width: 100%;
      position: relative;
      overflow: hidden;
      border-right: 10px solid #00935d;

      .left_shape {
        position: absolute;
        height: 150px;
        top: -33px;
        left: -46px;
      }
      .bottom_shape {
        position: absolute;
        height: 150px;
        bottom: -33px;
        right: -46px;
      }
      .reg_left_info {
        color: #ffffff;
        text-align: center;
        padding: 0 15px;
      }
    }
    .reg_right_area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-basis: 70%;
      .reg_form_area {
        padding: 60px 50px;
      }
      .reg_form_area h3 {
        color: #38bd8c;
      }
      .reg_form_area p {
        color: #135280;
      }
      .avatar {
        border-radius: 6px;
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-bottom: 10px;
      }
      .ant-input-affix-wrapper {
        box-shadow: none !important;
      }
      .ant-input-affix-wrapper-lg {
        border-radius: 4px !important;
      }
      .ant-select-selector {
        width: 100% !important;
        padding: 5px 11px !important;
        height: 100%;
      }
      .space__top {
        margin-top: 30px;
      }
    }
  }
}
