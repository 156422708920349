:root {
  --colorPrimary: #23253c;
  --colorSecondary: rgba(5, 5, 5, 0.6);
  --colorPrimaryBg: #fff;
  // --colorSecondaryBg: #91caff;
  --colorSecondaryBg: rgb(35, 37, 60);
  --colorPrimaryBorderHover: #69b1ff;
  --colorText: rgba(0, 0, 0, 0.88);
  --colorBorder: rgba(5, 5, 5, 0.06);
}

html[data-theme="dark"] {
  --colorPrimary: #0a244d;
  --colorPrimaryBg: #111a2c;
  --colorPrimaryBorder: #15325b;
  --colorPrimaryBorderHover: #15417e;
  --colorText: rgba(255, 255, 255, 0.85);
}

$colorPrimary: var(--colorPrimary);
$colorSecondary: var(--colorSecondary);
$colorPrimaryBg: var(--colorPrimaryBg);
$colorPrimaryBorder: var(--colorPrimaryBorder);
$colorPrimaryBorderHover: var(--colorPrimaryBorderHover);
$colorText: var(--colorText);
$colorBorder: var(--colorBorder);
$colorSecondaryBg: var(--colorSecondaryBg);
