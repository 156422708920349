@import "global";

.sidebar_skeleton {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    padding: 50px 20px;
  }
  
