.tranx_table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    td .ant-input-number-input {
      font-weight: bold;
      text-align: right;
    }
    td .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .tranx_select {
    font: inherit;
    width: 100%;
    background: none;
    height: 2.75em;
    padding: 0 4em 0 0.25em;
    border: 1px solid #2f4e67;
    border-radius: 0.5em;
    cursor: pointer;
  
    option {
      color: inherit;
    }
    
    &:focus {
      outline: none;
    }
    &::-ms-expand {
      display: none;
    }
  }

  .tranx_input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #2f4e67;
    margin: 0;
    padding: 6px 4px;
    background: none;
    color: #545454;
    font-size: 14px;
  }