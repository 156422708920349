.loader {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background: #fff;
  // z-index: 9999;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // .loader__spinner {
  //   width: 50px;
  //   height: 50px;
  //   border: 10px solid $colorPrimaryBorder;
  //   border-top: 10px solid $colorPrimary;
  //   border-radius: 50%;
  //   animation: spin 2s linear infinite;
  // }
  // @keyframes spin {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }
}

.g_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
