// GLOBAL STYLES
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif, "Satisfy", "cursive";
  // font-family: "Inter", sans-serif;
  // font-family: 'Jost', sans-serif;
}

.text__right {
  text-align: center !important;
}
// Input
// .cis__form {
//   .ant-input-affix-wrapper {
//     // &:focus-visible {
//     //   box-shadow: 0 0 0 1px rgba(52, 64, 84, 0.2) !important;
//     // }
//     box-shadow: 0 0 0 1px rgba(52, 64, 84, 0.2) !important;
//   }
//   .ant-form-item-label {
//     padding: 0 0 6px !important;
//     > label {
//       color: #344054 !important;
//       font-style: normal;
//       font-weight: 500;
//       line-height: 20px;
//     }
//     .anticon {
//       font-size: 13px !important;
//     }
//   }
//   // .ant-input-number-handler-wrap{
//   //   display: none!important;
//   // }

//   .ant-input {
//     font-size: 15px !important;
//     &:focus {
//       box-shadow: 0 0 0 1px rgba(52, 64, 84, 0.2) !important;
//     }
//   }
//   .ant-select-selector {
//     box-shadow: 0 0 0 1px rgba(52, 64, 84, 0) !important;
//   }
//   .react-tel-input .form-control:focus {
//     border-color: $colorPrimary !important;
//     box-shadow: 0 0 0 1px rgba(52, 64, 84, 0.2) !important;
//   }
//   .ant-input-number-group-wrapper {
//     width: 100%;
//   }
//   .ant-rate {
//     display: flex;
//     height: 40px;
//     align-items: center;
//     span.anticon.anticon-star {
//       font-size: 32px;
//     }
//   }

//   .ant-input-number-input {
//     font-size: 15px !important;
//   }
//   .ant-input-number-focused {
//     box-shadow: 0 0 0 1px rgba(52, 64, 84, 0.2) !important;
//   }
//   .ant-input-affix-wrapper {
//     &:focus {
//       box-shadow: 0 0 0 1px rgba(52, 64, 84, 0.2) !important;
//     }
//   }

//   .ant-select-selection-placeholder {
//     font-size: 15px;
//   }
// }

// .cis__from.ant-form-virtical {
//   .ant-row.ant-form-item-row {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//   }
// }

// List Table
.cis__table {
  .ant-tag {
    // padding: 4px 12px;
    font-size: 12px;
    background: #ededed;
    font-weight: 700;
    border-radius: 5px;
    width: 75px;
    text-align: center;
  }
  .ant-tag-success {
    color: #363636;
  }
  .ant-tag-error {
    color: #cd4141;
  }
  &__profile {
    display: flex;
    gap: 10px;
    align-items: center;
    a {
      font-weight: 500;
      color: $colorPrimary;
    }
  }
  &__amount-column {
    gap: 10px;
    text-align: right;
    a {
      font-weight: 500;
      color: $colorPrimary;
    }
  }
  .link__cell {
    font-weight: 500;
    // color: $colorPrimary;
  }
}
// .ant-card-bordered{
//   border: none;
// }

// .ant-image-mask{
//   margin-bottom: 12px
// }

// .ant-card-meta{
//   padding: 5;
// }

.ant-card-meta{
  margin: -4px 0;
}


// .ant-card-meta-avatar{
//   margin-left: 5px;
// }

// .ant-card-meta-description{
//   font-size: 16px;
//   color: #263144;
//   font-weight: 500;
//   margin-bottom: 5px;
// }

// .ant-card-meta-detail{
//   padding: 5;
// }

// .ant-card-meta-title{
//   font-size: 22px;
//   color: #000000;
//   font-weight: 500;
// }

.chms__table {
  .ant-card-head {
    // min-height: 44px;
    background: $colorPrimary;
    .ant-card-head-title {
      color: $colorPrimaryBg;
      padding: 10px 0;
    }
  }
  .ant-table-thead > tr > th {
    padding: 4px 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 4px 8px;
    border-bottom: 1px solid #f9f7f7;
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
  .ant-card-extra {
    padding: 10px 0;
  }
  span.ant-tag.ant-tag-has-color {
    margin: 0;
  }

  .ant-table-tbody .ant-table-cell{
    padding: 2px 8px;
    // .ant-input{
    //   padding: 2px 8px;
    // }
    .ant-form-item-control-input {
      min-height: 20px;
      padding: 2px 0;
    }
    .ant-form-item{
      margin: 0;
      .ant-input-number{
        margin-right: 0;

        .ant-input-number-input{
          padding: 2px 8px !important;
          height: 20px;
        }
      }
    }
  }
}

.product__details__container {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  margin-top: 15px;
  margin-bottom: 20px;

  hr {
    margin: 4px 0;
  }

  b {
    font-weight: 450;
  }

.first__container {
  width: 50%;
  border: 1px solid #f2f2f2;
  border-radius: 0.5rem;

  .vendor__info {
    padding: 1rem 1.5rem;

    p {
      margin-bottom: 8px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }
  }

  .ant-form-item-row {
    width: 95% !important;
    margin: 0 auto !important;
  }
}

.second__container {
  width: 50%;
  border: 1px solid #f2f2f2;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .order__summary {
    padding: 1rem 1.5rem;

    p {
      margin-bottom: 8px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin-bottom: 5px;
      }
    }
  }
}
}

.purchase_details-table {
  width: 100%;
  margin-top: 20px;
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}

.serial__num-table {
  table,
  thead,
  tbody {
    th {
      padding: 5px !important;
    }
    td {
      padding: 5px !important;
    }
  }
}


.cis__table__breadcrumb {
  .ant-card{
    border: hidden;
  }
  .ant-card-head {
    border: none;
  }
  .ant-card-body {
    padding: 0;
  }
  margin-bottom: 24px;
}

.cis-form__submitbtns {
  margin-top: 30px !important;
}

.ant-select-item-option-selected {
  background-color: rgba(0, 0, 0, 0.04) !important;
  .ant-select-item-option-content {
    font-weight: 500;
  }
}

// add by srrobin , if other solution remove  this css ----------------------------------->> NOTICE
.ant-message{
  z-index: 2000;
}