.dashboard {
  .card__paddingless .ant-card-body {
    padding: 10px !important;
  }
  .card__border__radiusless.ant-card {
    border-radius: 0px !important;
  }
  .card__title {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #23253c;
    padding: 12px 0;
    // margin-top: 40px;
  }
  .ant-layout {
    min-height: 100vh;
    .ant-layout-sider.ant-layout-sider-collapsed .sidebar__menu__area {
      display: flex;
      align-items: center;
      justify-content: fle;
      padding: 15px 26px;
      gap: 20px;
      border-inline-end: 1px solid $colorBorder;
      text-align: center;
    }
    .ant-layout-sider.ant-layout-sider-collapsed
      .sidebar__menu__area
      .logo__area {
      display: none;
    }

    .ant-layout-sider {
      background-color: $colorPrimaryBg !important;

      .sidebar__menu__area {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 10px 26px;
        // gap: 20px;
        border-bottom: 1px solid $colorBorder;
        .icon-menu-ex {
          cursor: pointer;
          font-size: 19px;
        }
        .icon-menu {
          cursor: pointer;
          font-size: 29px;
        }

        .logo__area {
          display: block;

          .icon {
            font-size: 29px;
          }
          .brand__name {
            font-size: 17px;
            font-weight: 500;
          }
        }
      }

      // Dashboard left side Menu area style

      main.menu {
        overflow-y: scroll;
        height: 93vh;
      }
      main.menu::-webkit-scrollbar {
        width: 0px;
      }
      .menu {
        .ant-menu {
          font-size: 16px !important;
          font-weight: 400;
          .ant-menu-item {
            border-radius: 0px;
            margin: 0 !important;
          }
        }
        .ant-menu .ant-menu-item-group-title {
          font-size: 15px !important;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 0 0 13px !important;
          color: #000;
        }
        .ant-menu-inline .ant-menu-item {
          height: 32px !important;
          width: calc(100% - 0px);
          font-size: 13px;
        }

        .ant-menu-light.ant-menu-inline .ant-menu-item::after {
          inset-block: 0;
          inset-inline-start: 0;
          border-inline-start: 6px solid rgb(242, 177, 53);
          transform: scaleX(0.0001);
        }
        .ant-menu-light.ant-menu-inline .ant-menu-item-selected::after {
          transform: scaleX(1);
        }
        // .ant-menu-item-group-list {
        // }
        .ant-menu-inline-collapsed .ant-menu-item-group-list {
          padding: 0 15px !important;
        }
        .ant-menu .ant-menu-item .ant-menu-item-icon {
          display: none;
        }
        .ant-menu-inline-collapsed.ant-menu .ant-menu-item .ant-menu-item-icon {
          display: block;
          padding-top: 4px;
        }
        .ant-menu-inline-collapsed .ant-menu-item-group-title {
          display: none;
        }
        .ant-menu-vertical.ant-menu-inline-collapsed .ant-menu-item {
          margin-inline: unset !important;
          width: auto !important;
        }
        .ant-menu-item-selected {
          position: relative;
        }

        .ant-menu-item {
          background-color: transparent;
          border-left: 6px solid transparent;
        }
        .ant-menu-light:not(.ant-menu-horizontal)
          .ant-menu-item:not(.ant-menu-item-selected):hover {
          background-color: rgba(202, 202, 202, 0.58);
          border-left: 6px solid rgb(104, 102, 102);
        }
        .ant-menu-item-selected {
          background-color: $colorSecondaryBg !important;
          color: $colorPrimaryBg !important;
          margin: 0px 0px !important;
          border-left: 0px solid transparent !important;
          // transition: .2s ease;
          a {
            transition: color 0s !important;
          }
        }
        .ant-menu-item-selected:active {
          background-color: $colorSecondaryBg !important;
          color: $colorPrimaryBg !important;
          margin: 0px 0px !important;
          border-left: 0px solid transparent !important;
          // transition: .2s ease;
          a {
            transition: color 0s !important;
          }
        }
        .ant-menu-item-active {
          transition: 0.2s ease;
          a {
            transition: color 0s !important;
          }
        }
      }
    }

    .overflow-ellipsis {
      /* Other existing styles */
      white-space: nowrap;      /* Prevents text from wrapping to the next line */
      overflow: hidden;         /* Hides any overflowing content */
      text-overflow: ellipsis;  /* Displays ellipsis (...) when content overflows */
    }
    

    // Dashboard  header top area style

    .header__area {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // padding-left: 24px;
      // padding-right: 24px;
      padding: 0 24px;
      background-color: #fff;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);

      .dashboard__title {
        font-size: 1.1rem;
      }
      .topbar__responsive__menu__btn {
        display: none;
      }
      @media screen and (max-width: 992px) {
        .topbar__responsive__menu__btn {
          display: block;
        }
      }
      .hader__notification__area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 45px;
      }
    }
    @media screen and (max-width: 768px) {
      .header__area {
        width: 100% !important;
      }
    }

    .ant-layout-sider-trigger {
      background: transparent !important;
      .menu__footer__item {
        background: #fbfbfb !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        color: #000;
        padding: 24px 9px;
        font-weight: 500;
        max-width: 248px;
        padding-left: 25px;
        .icon-log-out {
          font-weight: 800;
          padding-top: 5px;
          font-size: 18px;
        }
      }
    }
    .ant-layout-sider-collapsed {
      background: transparent !important;
      .menu__footer__item {
        background: #fbfbfb !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 9px;
        font-weight: 500;
        .icon-log-out {
          font-weight: 800;
          padding-top: 5px;
          font-size: 25px;
          color: #ff0000;
        }
        .logout {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .sidebar__area {
      display: none;
    }
    .item__one {
      display: none;
    }
    .item__three {
      display: none;
    }
    .item__four {
      display: none;
    }
    .dashboard__title {
      display: none;
    }
  }

  // dashboard element css

  .summery__card {
    padding: 10px;
    .summery__card__area {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-wrap: wrap;
      .summary__card__title {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 1rem;
        color: #3a3939;
      }
      @media screen and (max-width: 768px) {
        .summary__card__title {
          font-size: 0.8rem;
        }
      }

      .summary__card__info {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: baseline;
        .summery__percentage {
          font-size: 1.2rem;
          font-weight: 600;
          color: #3a3939;
        }
        @media screen and (max-width: 768px) {
          .summery__percentage {
            font-size: 1rem;
          }
        }
        .icon__color {
          color: $colorSecondary;
        }
      }
    }
  }

  // hotel metrix card css
  .hotel__metrix__top__arrea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 58px 15px 10px;
    flex-wrap: wrap;

    .metrix__left {
      .metirx__title {
        font-size: 1.3rem;
        font-weight: 500;
        color: #3a3939;
        span {
          font-size: 0.8rem;
          font-weight: 400;
          color: #817d7d;
        }
      }
      .metrix__sub {
        color: #585555;
        font-size: 0.8rem;
        padding: 7px 0;
      }
    }
    .metrix__right__box {
      border: 1px solid #079bf6;
      padding: 1px 10px;
      background: #079bf63d;
      color: #079bf6 !important;
      border-radius: 3px;
    }
    .metrix__right {
      color: #817d7d;
      span {
        font-size: 1.1rem;
        font-weight: 600;
        color: #000;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .hotel__metrix__top__arrea {
      padding-left: 20px;
    }
  }

  .hotel__metrix__bottom__arrea {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 22px 0px;
    font-weight: 600;
    color: #0eb0ff;
    cursor: pointer;
    flex-wrap: wrap;
    .icon {
      line-height: 7px !important;
    }
    .info__one {
      color: #3a3939;
      span {
        height: 10px;
        width: 10px;
        background: #0088fe;
        display: inline-block;
        border-radius: 50%;
      }
    }
    .info__two {
      color: #3a3939;
      span {
        height: 10px;
        width: 10px;
        background: #c5c5c5;
        display: inline-block;
        border-radius: 50%;
      }
    }
  }
  .metrix__bottom__area {
    text-align: center;
    padding: 19px;
    cursor: pointer;
    color: dodgerblue;
    font-size: 0.9rem;
    font-weight: 600;
    i {
      vertical-align: middle;
      line-height: 0px;
    }
  }
  //dashboard block card css

  .block__card__area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .block__card__count {
      font-size: 1.6rem;
      font-weight: 600;
      color: #3a3939;
    }
    .block__card__status {
      text-transform: capitalize;
      // color: goldenrod;
      font-weight: 500;
      i.icon {
        color: #3f8ebf;
        font-size: 0.9rem;
      }
    }
    .block__card__total {
      text-transform: uppercase;
      color: #918c8c;
      font-weight: 400;
      padding-top: 15px;
      font-size: 0.75rem;
      span {
        color: #3c3535;
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .simple__table {
      overflow-x: scroll;
    }
  }
}

