.form__print__list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 16px;
  [aria-label="file-excel"] {
    color: #007842;
  }
  [aria-label="file-pdf"] {
    color: #e4001e;
  }
  [aria-label="printer"] {
    color: #000000;
  }
  [aria-label="file-word"] {
    color: #2b4694;
  }
  [aria-label="unordered-list"] {
    color: #000000;
  }
  &__icon {
    background: #fcfafc;
    padding: 6px;
    border-radius: 50%;
    color: #000;
    width: 28px;
    height: 28px;
    cursor: pointer;
    svg {
      font-size: 16px;
    }
  }
}
