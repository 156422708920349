.payment-modal-heading {
    text-align: center;
    margin-bottom: 16px;
  }
  
  .payment-modal-field {
    margin-bottom: 16px;
  }
  
  .payment-modal-select {
    width: 100%;
  }
  
  .payment-modal-input {
    width: 100%;
  }
  