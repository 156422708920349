.booking__details {
  .card__paddingless .ant-card-body {
    padding: 20px !important;
  }
  .card__border__radiusless.ant-card {
    border-radius: 0px !important;
  }
  .card__box__shadow.ant-card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  .card__custom__bg.ant-card {
    background: #fff;
  }
  .for__scroll.ant-card {
    height: 340px;
    // overflow: auto;
  }
  .ant-btn-link.pap {
    padding: 4px 10px;
}
  .card__innter__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .action__area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-direction: row;
      .action__button button {
        background: #23253c;
        color: #fff;
      }
    }
  }
  .checkout__button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .guest__stay__info {
    margin: 40px 0;
    .checkin__checkout__area {
      .check__in__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        font-size: 16px;
      }
      .ant-divider-horizontal {
        margin: 2px 0 !important;
      }
      .ant-divider-dashed {
        background: none;
        border-color: #23253c6e;
        border-style: dashed;
        border-width: 1px 0 0;
      }
    }
  }
  .guest__rest__info {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    row-gap: 10px;
    margin-top: 25px;
    .single__info {
      background: #f2f2f2;
      padding: 10px 20px;
      color: #4f4242;
      border: 1px solid rgba(5, 5, 5, 0.06);
      border-radius: 5px;
    }
  }

  .balance__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top:20px;
    .single__blance {
      width: 31%;
      padding: 10px;
      border-radius: 5px;
      background: #f0f5ff;
      border: 0.09rem solid rgba(35, 37, 60, 0.3294117647);
      color: #23253c;
      line-height: 14px;
      font-weight: 500;
      font-size: 0.8rem;
      text-transform: capitalize;
      .blance__amount {
        font-weight: 600;
        margin-top: 10px;
        color: #0e4535;
      }
    }
  }
  .included__title {
    margin: 22px 0px;
    font-weight: 500;
    // letter-spacing: 0.2rem;
  }
  // .included__info {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin-top: 10px;
  //   flex-wrap: wrap;
  //   .single__included {
  //     width: 32.5%;
  //     padding: 6px 10px;
  //     display: flex;
  //     align-items: center;
  //     /* justify-content: left; */
  //     border-radius: 5px;
  //     box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  //     flex-wrap: wrap;
  //     row-gap: 5px;
  //     padding: 10px;
  //     border-radius: 5px;
  //     background: #fa8c16;
  //     border: none;
  //     color: rgba(0, 0, 0, 0.45);
  //     line-height: 14px;
  //     font-weight: 400;
  //     font-size: 0.9rem;
  //     text-transform: capitalize;
  //     flex-direction: column;
  //     margin-bottom: 6px;
  //     .fee__amount {
  //       font-weight: 400;
  //       margin-top: 10px;
  //       font-size: 1.4rem;
  //       color: #0e4535;
  //     }
  //   }
  // }
  .blance__footer__title {
    margin: 30px 0px;
    color: brown;
  }

.details__title{
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #23253c;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0;
}

  
}
.general__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  .gsingle__info {
    display: flex;
    flex-direction: column;
    text-align: center;
    span {
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 500;
      background: #f0f5ff;
      border: 0.09rem solid rgba(35, 37, 60, 0.3294117647);
      color: #0e4535;
      line-height: 14px;
      font-weight: 500;
      font-size: 0.82rem;
    }
  }
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

// .booking__payment__table.ant-table-wrapper .ant-table-thead > tr > th,
// .booking__payment__table .ant-table-wrapper .ant-table-thead > tr > td {
//   position: relative;
//   color: #23253c;
//   font-weight: 600;
//   text-align: start;
//   background: #f0f5ff !important;
//   border-bottom: 1px dashed #23253c;
//   transition: background 0.2s ease;
// }
// .booking__payment__table .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th{
// //   border-inline-end: none !important;
// }
.booking__payment__table .ant-table-wrapper .ant-table-tbody > tr > td {
  transition: background 0.2s, border-color 0.2s;
  border-bottom: 1px dashed #adc6ff !important;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-inline-end: none !important;
}

.guest_info {
  .main {
    display: grid;
    grid-gap: 20px;
  }

  .container {
    background-color: #fafafa;
    width: 100%;
    height: auto;
    min-height: 295px;
    justify-self: center;
    position: relative;
    transition: 100ms;
  }

  .follow:hover,
  .icon:hover,
  .profile-img:hover {
    cursor: pointer;
  }

  .profile-img {
    position: relative;
    width: 115px;
    height: 115px;
    object-fit: cover;
    border-radius: 50%;
    z-index: 1;
    margin: 2px 10px 0px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
    padding: 5px;
  }

  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    object-fit: cover;
    z-index: 0;
  }

  .name {
    font-size: 22px;
    position: absolute;
    right: 20px;
    top: 53%;
  }

  .bio {
    margin: 15px;
    margin-top: 0px;
  }

  .icon {
    width: 19px;
    position: absolute;
    margin-left: -28px;
    margin-top: 3px;
  }
}



.accomodation_numbar .ant-input-number-handler-up[aria-disabled="true"] svg,
.accomodation_numbar.ant-input-number-handler-down[aria-disabled="true"] svg {
  color: red; /* Change the color to your preferred color */
}
// .highlighted-date {
//   background-color: #ffc53d;
//   border-radius: 50%;
//   color: #f0f5ff;
// }
// .ant-picker-cell-inner.highlighted:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: #ffc53d;
//   border-radius: 50%;
//   pointer-events: none;
//   color: #f0f5ff;
// }
// .ant-picker-cell-selected .ant-picker-cell-inner,
// .selected-date {
//   background-color: #2a899d !important;
//   color: #fff !important;
// }

// .ant-picker-calendar-date {
//   background-color: skyblue !important;
// }
// .range-date {
//   background-color: lightgreen;
// }

// .highlighted-date {
//   background-color: skyblue;
// }

// .check-in-out-date {
//   background-color: #2a899d;
//   color: #fff;
// }
// .range-date {
//   background-color: lightgreen;
// }
// .range-date {
//   background-color: rgb(0, 255, 115);
// }
.highlighted-date {
  background-color: skyblue;
}

.check-in-out-date {
  background-color: #2a899d;
  color: #fff;
}
.booking_details_loader .ant-spin .ant-spin-dot-item{
  background-color: #10111c !important;
}
.content-transition {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

body.loaded .content-transition {
  opacity: 1;
}

.table__title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #37394a;
}
