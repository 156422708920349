.user-dropdwon {
  &__info {
    display: flex;
    align-items: flex-start;
    padding: 20px 25px;
    border-radius: 8px;
    margin-bottom: 12px;
    background: rgb(244, 245, 247);
    gap: 10px;
  }
  &__links {
    li {
      list-style: none;
      margin: 0;
      a {
        width: 100%;
        left: 0px;
        right: 0px;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 10px 12px;
        font-size: 14px;
        transition: all 0.3s ease 0s;
        color: rgb(116, 116, 116);
        &:hover {
            background: rgba(130, 49, 211, 0.02);
            color: $colorPrimary;
            padding-left: 22px;
        }
      }
    }
  }
  &__bottomAction {
    // font-size: 13px;
    // font-weight: 500;
    // text-align: center;
    // border-radius: 0px 0px 6px 6px;
    // padding: 15px 0px;
    // background: rgb(244, 245, 247);
    // width: 100%;
    // display: inline-block;
    margin-top: 20px;
  }
}
