/*Form Styles*/
.ant-form {
  & > .ant-row {
    row-gap: 12px !important;
  }

  &-vertical {
    & .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.submit__btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  // &modal {
  //   margin-top: 0;
  // }
}

.submit__btn.roles{
  gap: 20px;
}

// .submit__btn.modal {
//   margin-top: 0 !important;
// }

// @media screen and (max-width: 450px) {
//   .submit__btn {
//     flex-direction: column;
//     button {
//       margin-right: 0 !important;
//       margin-bottom: 20px;
//     }
//   }
// }
