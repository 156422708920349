.action__popover {
  width: 120px;
  .ant-popover-inner {
    padding: 0 8px;
  }
  .ant-popover-title {
    min-height: auto;
    min-width: 100%;
    text-align: center;
    color: #535353;
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
  }
  .ant-popover-inner-content {
    padding: 0 3px;
  }
  &__btn {
    margin-bottom: 8px;
    font-size: 12px!important;
  }
  &__content {
    &__btns {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }
  }
}
