.sidebar__area {
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
    // height: 20px;
  }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: #888;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: #dd1010;
//   }
}
