// animation spin
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cis-customizer {
  position: absolute;
  right: 0;
  top: 200px;
  button {
    padding: 10px 10px !important;
    font-size: 18px;
    height: 42px;
    width: 50px !important;
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 5%);
    border-radius: 30px 0 0 30px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#fa8c16),
      to(#f5222d)
    );
    border: 0 none;
    color: $colorText;
    cursor: pointer;
  }
  .ant-btn:hover {
    background: -webkit-linear-gradient(left, #f5222d, #fa8c16);
    color: $colorText;
  }

  & .icon:before {
    display: block;
    animation: spin 2s linear infinite;
  }
}

