$bgOffwhite: #f6f6f6;
$bgWhite: #ffffff;
$bgDark: #23253c;
$textColor: #545454;
$headFoot: #81818191;

// Variables
$main: #0034bb;
$text: #2b2b2b;
$textD: #adadad;



.header {
  display: flex;
  background: #fff;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  .logo {
    font-size: 32px;
    font-weight: bold;
    color: $main;
  }
  .search {
    position: relative;
    .icon {
      position: absolute;
      top: 5px;
      left: 5px;
      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
    .form-control {
      padding: 8px 10px;
      border-radius: 8px;
      font-size: 1rem;
      width: 100%;
      background: rgba($main, 0.06);
      border: 1px solid transparent;
      color: $text;
      padding-left: 40px;
      outline: 0px;

      &:focus {
        background: #fff;
        border-color: $main;
      }
      &::placeholder {
        color: rgba($main, 0.5);
      }
    }
  }
}
.main-content {
  padding: 0px 20px;
}
.invoice-container {
  max-width: 800px;
  margin: auto;
  padding: 3rem;
  background: #fff;
  .top {
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-between;
    .top-left {
      color: $main;
    }
    .main {
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 500;
      display: block;
      margin-bottom: 0.5rem;
    }
    .code {
      font-size: 24px;
    }
    .date {
      font-size: 18px;
      color: $textD;
      margin-bottom: 10px;
    }
  }
}
.bill-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  color: $textD;
  .left,
  .right {
    max-width: 240px;
  }
  .text-m {
    font-size: 18px;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
    color: $text;
  }
}
.table-bill {
  table {
    width: 100%;
    border-collapse: 0px;
    border-spacing: 0px;
    font-size: 1rem;
  }
  th,
  td {
    text-align: left;
    padding: 10px 10px;
    font-weight: 500;
  }
  td {
    padding: 15px 10px;
  }
  th {
    text-transform: uppercase;
    color: $main;
    font-weight: 500;
  }
  .quantity {
    width: 100px;
  }
  .cost {
    text-align: right;
    white-space: nowrap;
  }
  .total {
    font-size: 24px;
    color: $main;
    td {
      font-weight: normal;
      border-top: 1px solid #ececec;
    }
    .number {
      text-align: right;
      font-weight: 600;
    }
  }
}
.actions {
  text-align: center;
  margin-top: 4rem;
  .btn {
    margin: 3px;
    padding: 8px 10px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    border: 0px;
    min-width: 130px;
    color: $main;
    border-radius: 8px;
    background: rgba($main, 0.06);
    cursor: pointer;
    &:hover {
      background: rgba($main, 0.1);
    }
  }
}
.note {
  text-align: center;
  margin-top: 1rem;
  font-size: 12px;
  color: $textD;
}

//Responsive

@media (max-width: 1320px) {
  body,
  html {
    font-size: 14px;
  }
  .invoice-container .top .date {
    font-size: 14px;
  }
  .invoice-container .top .main {
    font-size: 32px;
  }
  .invoice-container .top .code {
    font-size: 18px;
  }
  .bill-box .text-m {
    font-size: 14px;
  }
  .bill-box .title {
    font-size: 1rem;
  }
  .table-bill .total {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .invoice-container .top {
    display: block;
    .top-left {
      margin-bottom: 1rem;
    }
  }
  .bill-box {
    display: block;
  }
  .bill-box .left,
  .bill-box .right {
    max-width: 100%;
  }
  .table-bill {
    table,
    tbody,
    tfoot,
    td,
    th,
    tr {
      display: block;
    }
    th {
      display: none;
    }
    tbody tr {
      padding: 10px 0px;
      border-bottom: 1px solid #e5e5e5;
      td:first-child {
        display: none;
      }
    }
    td {
      padding: 5px 0px;
    }
    .cost {
      text-align: left;
    }
    .total {
      margin-top: 20px;
      td {
        border-top: 0px;
      }
      .number {
        text-align: left;
      }
    }
  }
}

@media (max-width: 420px) {
  .actions {
    .btn {
      width: 100%;
      margin: 5px 0px;
    }
  }
  .header {
    display: block;
  }
}

@mixin desktop {
  @media screen and (max-width: 1014px) {
    @content;
  }
}
.pos__wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 500px;
  @include desktop {
    grid-template-columns: 1fr 300px;
  }
}
//   @include desktop {
// .pos__wrapper {
//   display: grid;
//   gap: 10px;
//   grid-template-columns: 1fr 450px;
// }
//   }
.wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  background-color: #fff;
  color: #444;
  overflow: auto;
}
.box {
  background-color: $bgOffwhite;
  color: #fff;
  padding: 10px 5px;
  font-size: 1rem;
}
.head,
.foot {
  position: sticky;
  top: 0;
  background: $headFoot;
  font-size: 1.2rem;
}
.foot {
  top: auto;
  bottom: 0;
}
.boxcontent {
  position: relative;
}
.scroll__area {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: auto;
  overflow-y: scroll;
}
.scroll__area > div {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.scroll__area .tabs__menu {
  background: #000;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 5px 10px;
}

::-webkit-scrollbar {
  display: none;
}


.cart__area {
  .scroll__area {
    position: absolute;
    left: 0;
    top: 13px;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
    overflow-y: scroll;
    .cart__item__area {
      padding: 0 20px;
      .cart__items__position{
        display: flex;
        align-items: center;
        justify-content: center;
        .cart__item {
          @extend %flexareaspacebetween;
          column-gap: 15px;
          grid-column-gap: 15px;
          background: $bgOffwhite;
          padding: 10px;
          border-radius: 4px;
          background: #cdcdcd8a;
          position: relative;
          .ant-typography {
            margin-bottom: 0 !important;
          }
          .delete__icon {
            position: absolute;
            top: 0px;
            right: 5px;
            cursor: pointer;
            color: rgba(229, 26, 26, 0.568627451);
            font-size: 20px;
  
            &:hover {
              color: red;
            }
          }
          .counter__area {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background: $bgWhite;
            color: $textColor;
            padding: 8px;
            border-radius: 24px;
  
            button {
              border: navajowhite;
              background: none;
              font-size: 18px;
              color: $textColor;
              cursor: pointer;
              transition: all 0.3s;
  
              &:hover {
                color: #f6f6f6;
                background: $bgDark;
              }
            }
          }
          .cart__info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            align-items: flex-start;
  
            .cart__title {
              text-transform: capitalize;
              font-size: 14px;
              font-weight: 400;
              color: #545454;
              line-height: 16px;
              margin-bottom: 10px;
            }
  
            .cart__price {
              color: $textColor;
              line-height: 16px;
              margin-bottom: 10px;
              text-align: center;
            }
  
            .cart__quantity {
              color: $textColor;
              line-height: 16px;
              font-size: 11px;
            }
          }
  
          .main__price {
            color: $bgWhite;
            line-height: 16px;
            margin-bottom: 10px;
            text-align: center;
            background: $bgDark;
            padding: 3px 14px;
            border-radius: 17px;
            font-size: 13px;
            font-weight: 500;
            flex-basis: 100px;
            flex-grow: 0;
            flex-shrink: 0;
          }
        }
      }
    }
    @include desktop {
      .cart__item__area {
        padding: 0 20px;
        .cart__item {
          display: flex;
          align-items: center;
          flex-direction: column-reverse;
          .ant-typography {
            margin-bottom: 0 !important;
          }
          .delete__icon {
            position: absolute;
            top: 0px;
            right: 5px;
            cursor: pointer;
            color: rgba(229, 26, 26, 0.568627451);
            font-size: 20px;

            &:hover {
              color: red;
            }
          }
          .counter__area {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            background: #ffffff;
            color: #545454;
            padding: 0px 20px;
            border-radius: 24px;
            gap: 10px;
            flex-direction: row;

            button {
              border: navajowhite;
              background: none;
              font-size: 18px;
              color: $textColor;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                color: #f6f6f6;
                background: $bgDark;
              }
            }
          }
          .cart__info {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;

            .cart__title {
              margin-bottom: 0px;
              font-weight: 500;
            }

            .cart__price {
              margin-bottom: 0px;
            }

            .cart__quantity {
              color: $textColor;
              line-height: 16px;
              font-size: 11px;
            }
          }

          .main__price {
            margin-bottom: 10px;
            text-align: center;
            background: #23253c;
            padding: 3px 14px;
            border-radius: 17px;
            flex-basis: 0px;
          }
        }
      }
    }
  }
  .pos__accounting__area {
    padding: 0 15px;
    .pos__accounting__wrapper {

      .pos__total__calculation__area {
        margin: 14px 0;
        .pos__item__number {
          @extend %flexareaspacebetween;
          background: #e1dddd4f;
          padding: 0 16px;
          color: #031a3f;
          font-weight: 600;
          border-bottom: 1e-8rem dashed #031a3f80;
        }
        .pos__subtotal__cost {
          @extend %flexareaspacebetween;
          background: #e1dddd4f;
          padding: 0 16px;
          margin: 8px 0;
          color: #031a3f;
          font-weight: 600;
          border-bottom: 1e-8rem dashed #031a3f80;
        }
        .pos__total__cost {
          @extend %flexareaspacebetween;
          background: #e1dddd4f;
          padding: 0 16px;
          color: #031a3f;
          font-weight: 600;
          border-bottom: 1e-8rem dashed #031a3f80;
        }
      }
    }
    .pos__action__button__area {
      .pos__calculation__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }
}

.card__area {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .pos__top__action__area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-size: 24px;
    column-gap: 20px;
    @include desktop {
      row-gap: 10px;
    }

    .pos__top__action__btn {
      background: $bgDark;
      padding: 8px 9px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      color: #fff;
      display: inline-flex;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        border: 1px solid #111111;
      }
    }
  }

  .scroll__area {
    .tabs__menu {
      background: #cdcdcd8a;
      color: #fff;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding: 5px 10px;
      z-index: 99;
      .ant-tabs-tab {
        &:hover {
          color: #043103;
        }
      }
      .ant-tabs-top > .ant-tabs-nav {
        margin: 0px !important;
      }
      .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        height: 0px !important;
      }
      .ant-tabs-top > .ant-tabs-nav::before {
        border: none !important;
      }
      .ant-tabs-tab {
        padding: 8px 30px;
        margin: 0 0 0 0px !important;
      }
      .ant-tabs-tab-active {
        border-radius: 30px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
        background-color: #23253c;
        color: #fff;
        .ant-tabs-tab-btn {
          color: #fdfdfd !important;
        }
      }
    }
    .item__area {
      margin-top: 20px;
      padding: 5px 15px;
      .ant-card {
        padding: 1.3px;
        .ant-card-body {
          padding: 8px !important;
        }
        .ant-card-meta-title {
          font-weight: 500 !important;
          font-size: 14px !important;
        }
        .ant-card-meta-description {
          font-size: 12px;
          color: #263144;
          font-weight: normal;
        }
        .product__image {
          min-height: 150px;
          height: 100%;
        }
      }
    }
  }
}
// END POS new Design SASS

%flexareaspacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flexareastart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flexareacenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
%flexareaend {
  align-items: flex-end;
  display: flex;
  .pos__wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 1fr;
  }
}

.ant-qrcode {
  &.ant__qr__code {
    height: 90px !important;
    width: 90px !important;
    margin-bottom: 10px !important;
    canvas {
      height: 70px !important;
      width: 70px !important;
    }
  }
}

@media print {
  .ant-modal .ant-modal-content {
    box-shadow: none !important;
  }
  #printbtn {
    display: none;
  }
  .ant-modal .ant-modal-close {
    display: none;
  }
}
