.product__details__container {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
    margin-top: 15px;
    margin-bottom: 20px;
  
    hr {
      margin: 4px 0;
    }
  
    b {
      font-weight: 450;
    }
    .payment__object{
        table {
        width: 100%;
        border: 1px solid #f1f1f1;
      }
  
        th, td {
        padding: 10px;
        border: 1px solid #f1f1f1;
      }
  
      /* Responsive layout CSS */
         @media screen and (max-width: 600px) {
            table {
          display: block;
        }
  
            th, td {
          display: block;
          width: 100%;
          box-sizing: border-box;
        }
      }
}
  
  .first__container {
    width: 50%;
    border: 1px solid #f2f2f2;
    border-radius: 0.5rem;
  
    .vendor__info {
      padding: 1rem 1.5rem;
  
      p {
        margin-bottom: 8px;
      }
  
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        p {
          margin: 0;
        }
      }
    }
  
    .ant-form-item-row {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
  
  .second__container {
    width: 50%;
    border: 1px solid #f1f1f1;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  
    .order__summary {
      padding: 1rem 1.5rem;
  
      p {
        margin-bottom: 8px;
      }
  
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        p {
          margin-bottom: 5px;
        }
      }
    }
  }
  }
  
  .purchase_details-table {
    width: 100%;
    margin-top: 20px;
    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
  
  .serial__num-table {
    table,
    thead,
    tbody {
      th {
        padding: 5px !important;
      }
      td {
        padding: 5px !important;
      }
    }
  }

  /* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

/* Close Button */
.close {
  position: absolute;
  top: -7px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #999;
}

.close:hover {
  color: #555;
}

/* PDF Iframe */
.pdf-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Print Button */
.print-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.print-button:hover {
  background-color: #0056b3;
}
