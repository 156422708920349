@charset "UTF-8";

@font-face {
  font-family: "home-controller";
  src:url("fonts/home-controller.eot");
  src:url("fonts/home-controller.eot?#iefix") format("embedded-opentype"),
    url("fonts/home-controller.woff") format("woff"),
    url("fonts/home-controller.ttf") format("truetype"),
    url("fonts/home-controller.svg#home-controller") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "home-controller" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "home-controller" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-left:before {
  content: "\61";
}
.icon-right:before {
  content: "\62";
}
.icon-up:before {
  content: "\63";
}
.icon-down:before {
  content: "\64";
}
.icon-edit:before {
  content: "\65";
}
.icon-quote-left:before {
  content: "\66";
}
.icon-quote-right:before {
  content: "\67";
}
